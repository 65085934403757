<template>
  <v-container fluid>
    <page-header title="報表"> </page-header>

    <v-card class="mb-8">
      <v-card-text>
        <div class="d-flex align-center flex-wrap">
          <form-date-picker
            hideDetails
            class="mr-4"
            :fieldValue.sync="filter.filter_month"
            placeholder="月份"
            isYearMonthOnly
            disableFuture
            @changed="search()"
          />
          <form-select :fieldValue.sync="filter.direction" :options="sortOptions" hideDetails @changed="search()" />
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mb-8" v-if="order.show">
      <v-card-text>
        <v-card-title class="page-title">訂單報表</v-card-title>
        <datatable
          :tableHeaders="order.tableHeaders"
          :tableData="order.tableData"
          :page.sync="order.tablePage"
          :pageLimit.sync="order.tablePageLimit"
          :itemTotal="order.tableItemTotal"
          :isLoading="order.tableLoading"
          @options-update="getOrderReports()"
          enableExport
          @export-clicked="exportOrderReport()"
          disableShadow
        >
          <template #item.total="{item}"> ${{ item.total }} </template>
        </datatable>
      </v-card-text>
    </v-card>

    <v-card class="mb-8" v-if="job.show">
      <v-card-text>
        <v-card-title class="page-title">工作報表</v-card-title>
        <datatable
          :tableHeaders="job.tableHeaders"
          :tableData="job.tableData"
          :page.sync="job.tablePage"
          :pageLimit.sync="job.tablePageLimit"
          :itemTotal="job.tableItemTotal"
          :isLoading="job.tableLoading"
          @options-update="getJobReports()"
          enableExport
          @export-clicked="exportJobReport()"
          disableShadow
        ></datatable>
      </v-card-text>
    </v-card>

    <v-card class="mb-8" v-if="user.show">
      <v-card-text>
        <v-card-title class="page-title">用戶報表</v-card-title>
        <datatable
          :tableHeaders="user.tableHeaders"
          :tableData="user.tableData"
          :page.sync="user.tablePage"
          :pageLimit.sync="user.tablePageLimit"
          :itemTotal="user.tableItemTotal"
          :isLoading="user.tableLoading"
          @options-update="getUserReports()"
          enableExport
          @export-clicked="exportUserReport()"
          disableShadow
        ></datatable>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormDatePicker from '@/components/form/FormDatePicker.vue'
import ExcelMixin from '@/mixins/ExcelMixin.vue'

export default {
  name: 'RedeemList',
  mixins: [ExcelMixin],
  components: {
    Datatable,
    FormSelect,
    FormDatePicker,
  },
  data: () => ({
    order: {
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 10,
      tableData: [],
      tableItemTotal: 0,
      tableHeaders: [
        { text: '訂單編號', value: 'order_code' },
        { text: '用戶名稱', value: 'username' },
        { text: '科目', value: 'subject' },
        { text: '總額', value: 'total' },
        { text: '堂數', value: 'lesson_count' },
        { text: '訂單狀態', value: 'order_status' },
      ],
      show: true,
    },
    job: {
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 10,
      tableData: [],
      tableItemTotal: 0,
      tableHeaders: [
        { text: '工作編號', value: 'job_code' },
        { text: '學生', value: 'student_name' },
        { text: '課堂類別', value: 'lesson_category' },
        { text: '科目', value: 'subject' },
        { text: '導師', value: 'tutor_name' },
        { text: '狀態', value: 'status' },
      ],
      show: true,
    },
    user: {
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 10,
      tableData: [],
      tableItemTotal: 0,
      tableHeaders: [
        { text: '帳戶編號', value: 'user_code' },
        { text: '用戶類別', value: 'user_type' },
        { text: '用戶名稱', value: 'username' },
        { text: '電話', value: 'phone' },
        { text: '狀態', value: 'status' },
      ],
      show: true,
    },

    filter: {
      filter_month: '',
      direction: 'desc',
      sorting: 'create_date',
    },
    sortOptions: [
      { text: '由新到舊', value: 'desc' },
      { text: '由舊到新', value: 'asc' },
    ],
    exportLoading: false,
  }),

  methods: {
    async getOrderReports() {
      this.order.tableLoading = true

      this._getOrderReportsData()
        .then(list => {
          this.order.tableData = list
          this.order.tableLoading = false
        })
        .catch(err => {
          this.$common.error(err)
          this.order.tableData = []
          this.order.tableLoading = false
          if (err === 'noPermission') {
            this.order.show = false
          }
        })
    },
    async _getOrderReportsData() {
      return new Promise(async (resolve, reject) => {
        try {
          let payload = {
            limit: this.order.tablePageLimit,
            page: this.order.tablePage - 1,
            join_student_data: true,
            direction: this.filter.direction,
            sorting: this.filter.sorting,
          }

          if (this.filter.filter_month) {
            payload['filter_month'] = this.filter.filter_month
          }

          const user = this.getCurrentUserData()
          const { data, total } = await this.$Fetcher.GetOrders(payload, user.id, user.token)
          this.order.tableItemTotal = total
          const list = data.map(item => {
            const subject = this.$subjectOptions.find(el => item.subject.length && el.value === item.subject[0])
            const status = this.$orderStatusOptions.find(el => el.value === item.order_status)
            return {
              id: item.id,
              order_code: this.$Formatter.pad(item.id, 4),
              username: item.student ? item.student.name : '',
              subject: subject ? subject.text : '',
              total: item.total,
              lesson_count: item.course_timetable.length,
              order_status: status ? status.text : '',
            }
          })
          resolve(list)
        } catch (err) {
          this.$common.error(err)
          if (err && err !== 'noPermission') {
            this.$store.dispatch('toggleSnack', {
              type: 'error',
              message: err,
            })
          }
          reject(err)
        }
      })
    },
    async exportOrderReport() {
      if (this.exportLoading) {
        this.$store.dispatch('toggleSnack', {
          show: true,
          message: 'processing',
        })
        return
      }

      this.exportLoading = true
      this._getOrderReportsData()
        .then(list => {
          try {
            let header = {}
            this.order.tableHeaders.forEach(item => {
              header[item.value] = item.text
            })

            this.exportExcel(header, list, '訂單報表', `訂單報表.xlsx`)
          } catch (err) {
            this.$common.error(err)
            this.$store.dispatch('toggleSnack', {
              show: true,
              type: 'error',
              message: '匯出失敗',
            })
          } finally {
            this.exportLoading = false
          }
        })
        .catch(() => {
          this.exportLoading = false
        })
    },

    async getJobReports() {
      this.job.tableLoading = true

      this._getJobReportsData()
        .then(list => {
          this.job.tableData = list
          this.job.tableLoading = false
        })
        .catch(err => {
          this.job.tableData = []
          this.job.tableLoading = false
          if (err === 'noPermission') {
            this.job.show = false
          }
        })
    },
    async _getJobReportsData() {
      return new Promise(async (resolve, reject) => {
        try {
          let payload = {
            limit: this.job.tablePageLimit,
            page: this.job.tablePage - 1,
            join_student_data: true,
            join_tutor_data: true,
            direction: this.filter.direction,
            sorting: this.filter.sorting,
          }

          if (this.filter.filter_month) {
            payload['filter_month'] = this.filter.filter_month
          }

          const user = this.getCurrentUserData()
          const { data, total } = await this.$Fetcher.GetJobs(payload, user.id, user.token)
          this.job.tableItemTotal = total
          const list = data.map(item => {
            const subject = this.$subjectOptions.find(el => item.subject.length && el.value === item.subject[0])
            const status = this.$statusOptions.find(el => el.value === item.status)
            const cate = this.$lessonCategoryOptions.find(el => el.value === item.lesson_category)
            return {
              id: item.id,
              job_code: this.$Formatter.pad(item.id, 4),
              student_name: item.student ? item.student.name : '',
              lesson_category: cate ? cate.text : '',
              subject: subject ? subject.text : '',
              tutor_name: item.tutor ? item.tutor.name : '',
              status: status ? status.text : '',
            }
          })
          resolve(list)
        } catch (err) {
          this.$common.error(err)
          if (err && err !== 'noPermission') {
            this.$store.dispatch('toggleSnack', {
              type: 'error',
              message: err,
            })
          }
          reject(err)
        }
      })
    },
    async exportJobReport() {
      if (this.exportLoading) {
        this.$store.dispatch('toggleSnack', {
          show: true,
          message: 'processing',
        })
        return
      }

      this.exportLoading = true
      this._getJobReportsData()
        .then(list => {
          try {
            let header = {}
            this.job.tableHeaders.forEach(item => {
              header[item.value] = item.text
            })

            this.exportExcel(header, list, '工作報表', `工作報表.xlsx`)
          } catch (err) {
            this.$common.error(err)
            this.$store.dispatch('toggleSnack', {
              show: true,
              type: 'error',
              message: '匯出失敗',
            })
          } finally {
            this.exportLoading = false
          }
        })
        .catch(() => {
          this.exportLoading = false
        })
    },

    async getUserReports() {
      this.user.tableLoading = true

      this._getUserReportsData()
        .then(list => {
          this.user.tableData = list
          this.user.tableLoading = false
        })
        .catch(err => {
          this.user.tableData = []
          this.user.tableLoading = false
          if (err === 'noPermission') {
            this.user.show = false
          }
        })
    },
    async _getUserReportsData() {
      return new Promise(async (resolve, reject) => {
        try {
          let payload = {
            limit: this.user.tablePageLimit,
            page: this.user.tablePage - 1,
            direction: this.filter.direction,
            sorting: this.filter.sorting,
          }

          if (this.filter.filter_month) {
            payload['filter_month'] = this.filter.filter_month
          }

          const user = this.getCurrentUserData()
          const { data, total } = await this.$Fetcher.GetUsers(payload, user.id, user.token)
          this.user.tableItemTotal = total
          const list = data.map(item => {
            const status = this.$statusOptions.find(el => el.value === item.status)
            return {
              id: item.id,
              user_code: this.$Formatter.pad(item.id, 4),
              user_type: item.data_type === 'student_data' ? '學生' : item.data_type === 'tutor_data' ? '導師' : '',
              username: item.name,
              phone: item.phone,
              status: status ? status.text : '',
            }
          })
          resolve(list)
        } catch (err) {
          this.$common.error(err)
          if (err && err !== 'noPermission') {
            this.$store.dispatch('toggleSnack', {
              type: 'error',
              message: err,
            })
          }
          reject(err)
        }
      })
    },
    async exportUserReport() {
      if (this.exportLoading) {
        this.$store.dispatch('toggleSnack', {
          show: true,
          message: 'processing',
        })
        return
      }

      this.exportLoading = true
      this._getUserReportsData()
        .then(list => {
          try {
            let header = {}
            this.user.tableHeaders.forEach(item => {
              header[item.value] = item.text
            })

            this.exportExcel(header, list, '用戶報表', `用戶報表.xlsx`)
          } catch (err) {
            this.$common.error(err)
            this.$store.dispatch('toggleSnack', {
              show: true,
              type: 'error',
              message: '匯出失敗',
            })
          } finally {
            this.exportLoading = false
          }
        })
        .catch(() => {
          this.exportLoading = false
        })
    },

    search() {
      this.order.tablePage = 1
      if (this.order.show) {
        this.getOrderReports()
      }
     
      this.job.tablePage = 1
      if (this.job.show) {
        this.getJobReports()
      }
   
      this.user.tablePage = 1
      if (this.user.show) {
        this.getUserReports()
      }
   
    },
  },

  mounted() {
    this.getOrderReports()
    this.getJobReports()
    this.getUserReports()
  },
}
</script>
